<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <div>
    <!-- Search input -->
    <!-- <div class="d-flex align-items-center mb-3">
      <div class="h3 m-0" style="flex: 3;">Total: {{ totalRows }}</div>
    </div> -->
    <!-- Sort -->
    <div class="d-flex align-items mb-2">
      <div style="flex: 3;">
        <b-link to="add">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
          >
            <feather-icon icon="PlusCircleIcon" class="mr-50" />
            <span class="align-middle">Add new</span>
          </b-button>
        </b-link>
      </div>
      <!-- Sort -->
      <b-form-group
        label="Sorted by"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0 d-flex align-items-center justify-content-center"
        style="flex: 1;"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </div>
    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      borderless
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(avatar)="item">
        <b-avatar :src="item.item.avatar" />
      </template>
      <template #cell(name)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.name }}
        </b-link>
      </template>
      <template #cell(title)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.title }}
        </b-link>
      </template>
      <template #cell(fullname)="item">
        <b-link :to="`edit/${item.item.id}?lang=${currentLang}`">
          {{ item.item.fullname }}
        </b-link>
      </template>
      <template #cell(rating)="item">
        <b-form-rating
          :value="item.item.rating"
          no-border
          variant="warning"
          readonly
          size="sm"
          inline
        />
      </template>
      <template #cell(is_active)="item">
        <ActiveStatus :value="item.item.is_active" />
      </template>
      <!-- <template #cell(lang)="item">
        <div class="text-center">
          <LanguageSelect :value="item" :languageList="languageList" />
        </div>
      </template> -->
      <template #cell(more)="item">
        <div class="text-center">
          <!-- <b-link
            :to="{ path: `detail/${item.item.id}` }"
            class="text-secondary"
          >
            <feather-icon icon="EyeIcon" class="mr-50" />
          </b-link> -->
          <b-link
            :to="{ path: `edit/${item.item.id}` }"
            class="text-secondary mx-1"
          >
            <feather-icon icon="Edit3Icon" class="mr-50" />
          </b-link>
          <div class="text-danger" @click="handleDelete(item.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
          </div>
        </div>
      </template>
      <template #cell(sort)="item" class="text-center">
        <div class="text-center">
          <feather-icon
            :class="{ disabled: items[item.index - 1] === undefined }"
            icon="ArrowUpIcon"
            class="text-success mr-1"
            size="18"
            @click="handleSort(item, 'up')"
          />
          <feather-icon
            :class="{ disabled: items[item.index + 1] === undefined }"
            icon="ArrowDownIcon"
            class="text-danger"
            size="18"
            @click="handleSort(item, 'down')"
          />
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="10" sm="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
        <!-- <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        /> -->
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAvatar,
  BPagination,
  BTable,
  BRow,
  BCol,
  BButton,
  BLink,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormRating,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import ActiveStatus from '@/views/components/active-status/index.vue'
// import LanguageSelect from '@/views/components/language-select/index.vue'

export default {
  components: {
    BButton,
    BLink,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    ActiveStatus,
    // LanguageSelect,
    BAvatar,
    BPagination,
    BTable,
    BRow,
    BCol,
    BFormRating,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      languageList: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  //   watch: {
  //       items(val) {
  //           if (val) {
  //               this.totalRows
  //           }
  //       }
  //   },
  async mounted() {
    this.languageList = await this.loadLanguage()
    this.totalRows = this.items.length
  },
  methods: {
    handleSort(item, method) {
      this.$emit('handleSort', { item, method })
    },
    handleDelete(id) {
      this.$emit('handleDelete', id)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
